
import { Injectable, NgModule } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpClientModule } from '@angular/common/http';
// RxJS
import { Observable, BehaviorSubject } from 'rxjs';
import { ConditionSearchModel, } from '../models/ConditionSearchModel';
import { RoomModel, } from '../models/RoomModel';
import { environment } from '../../environments/environment';
// Model
import { MasterModel } from './../models/MasterModel';
import { TransferModel } from './../models/TransferModel';
import { ScheduleMeetingRoomModel } from './../models/ScheduleMeetingRoomModel';
import { ScheduleMeetingTimeModel } from './../models/ScheduleMeetingTimeModel';
import { ScheduleMeetingMeetingModel } from './../models/ScheduleMeetingMeetingModel';
import * as moment from 'moment';

@Injectable()
export class WebServicePayment {

    constructor(private http: HttpClient,) { }
        
    headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
    });

      
    SaveDataPaymentQRCode(amount: number): Observable<string> {
      
        const userToken = localStorage.getItem("access_token");
        const httpHeaders = new HttpHeaders({        
          Authorization: "Bearer " + userToken,
        });
        
        const condition = new ConditionSearchModel();
        condition.amount = amount;

        const url = `${environment.API_SERVICES_URL}PromptPayQrCode/GetPromptPayQrCode`;
        return this.http.post<string>(url, condition, { headers: httpHeaders });
    }
}

export class ConditionSearchModel {
    id: number;
	search: string;
	code: string;
	name: string;
	doc_no: string;
	doc_ref: string;
	doc_date: string;
	date_start: string;
	date_end: string;
	date_start_s: string;
	date_end_s: string;
	employee_id: number;
	customer_id: number;
	company_id: number;
	status_id: number;
	time_rage_id: number;
	description: string;
	bank_id: number;
	day: number;
	month: number;
	year: number;
	amount: number;
	total_price: number;
	type_id: number;
	image_file: FormData;
	image_file_string: string;
	payment_date: string;
	
	clear() {
		this.id = 0;
		this.search = '';
		this.code = '';
		this.name = '';
		this.doc_no = '';
		this.doc_ref = '';
		this.doc_date = '';
		this.date_start = '';
		this.date_end = '';
		this.date_start_s = '';
		this.date_end_s = '';
		this.employee_id = 0;
		this.customer_id = 0;
		this.company_id = 0;
		this.status_id = 0;
		this.time_rage_id = 0;
		this.description = '';
		this.bank_id  = 0;
		this.day = 0;
		this.month = 0;
		this.year = 0;
		this.amount = 0;
		this.total_price = 0;
		this.type_id = 0;
		//this.image_file = null;
		this.image_file_string = '';
		this.payment_date = '';
	}
}
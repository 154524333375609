import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
//--model
import { ConditionSearchModel } from './../models/ConditionSearchModel';
import { CustomerModel } from './../models/CustomerModel';
//-- services
import { WebServiceLine } from './../services/web.service-line';

@Component({
  selector: 'app-modal-line-register',
  templateUrl: './modal-line-register.component.html',
  styleUrls: ['./modal-line-register.component.scss'],
})
export class ModalLineRegisterComponent implements OnInit {
  // Data passed in by componentProps
  @Input() title_header: string;
  @Input() model: CustomerModel;

  constructor(
    public modalCtrl: ModalController,
    private webServiceLine: WebServiceLine,
  ) { }

  ngOnInit() {

  }


  async close() {
    await this.modalCtrl.dismiss({ response: 1 });
  }

  dismiss() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

  saveData() {
    if (this.model.first_name == '' || this.model.first_name == null)
      alert('กรุณาระบุชื่อก่อน!');
    else if (this.model.last_name == '' || this.model.last_name == null)
      alert('กรุณาระบุนามสกุลก่อน!');
    else if (this.model.telephone == '' || this.model.telephone == null)
      alert('กรุณาระบุเบอร์โทรศัพท์ก่อน!');
    else {
      this.webServiceLine.saveDataCustomerRegister(this.model).subscribe(result => {
        if (result == 'success') {
          this.dismiss();
        }
        else if (result == 'duplicate_name') {
          alert('ชื่อนี้ของคุณเป็นสมาชิกอยู่แล้ว!');
        }
        else if (result == 'duplicate_telephone') {
          alert('เบอร์โทรนี้ของคุณเป็นสมาชิกอยู่แล้ว!');
        }
        else if (result == 'error') {
          alert('บันทึกข้อมูลไม่สำเร็จ!');
        }
      });
    }
  }

}

export class ScheduleMeetingMeetingModel {
	rowNo: number;
    id: number;
	room_id: number;
	room_name: string;
	room_size_id: number;
	room_size_name: string;
	room_type_id: number;
	room_type_name: string;
	room_table_id: number;
	room_table_name: string;
	time_id: number;
	time_name: string;
	time_date: string;
	time_date_string: string;
	rage_time_id:  number;
	rage_time_name: string;
	time_class: string;
	colspan: number;
	status_none: boolean;
	status_id: number;
	status_name: string;
	payment_status_name: string;
	customer_id: number;
	customer_name: string;
	customer_telephone: string;
	price: number;
	credit_minute: number;
	quantity: number;
	sub_total: number;
	discount_total: number;
	vat_type_id: number;
	vat_type_name: string;
	vat_per: number;
	vat_total: number;
	price_vat_before: number;
	price_vat_after: number;
	net_total: number;
	payment_total: number;
	payment_ref_no: string;
	payment_qr_code: string;
	payment_qr_total: number;
	payment_slip: string;
	css_img: string;
	css_room: string;
	css_room_size: string;
	mode: string;
	work_plan_field_id: number;

	clear() {
		this.rowNo = 0;
		this.id = 0;
		this.room_id = 0;
		this.room_name = '';
		this.room_size_id = 0;
		this.room_size_name = '';
		this.room_type_id = 0;
		this.room_type_name = '';
		this.room_table_id = 0;
		this.room_table_name = '';
		this.time_id = 0;
		this.time_name = '';
		this.time_date = '';
		this.time_date_string = '';
		this.rage_time_id = 0;
		this.rage_time_name = '';
		this.time_class = '';
		this.colspan = 0;
		this.status_none = false;
		this.status_id = 0;
		this.status_name = '';
		this.payment_status_name = '';
		this.customer_id = 0;
		this.customer_name = '';
		this.customer_telephone = '';
		this.status_id = 0;
		this.status_name = '';
		this.price = 0;
		this.credit_minute = 0;
		this.quantity = 0;
		this.sub_total = 0;
		this.discount_total = 0;
		this.vat_per = 0;
		this.vat_total = 0;
		this.vat_type_id = 0;
		this.vat_type_name = '';
		this.price_vat_before = 0;
		this.price_vat_after = 0;
		this.net_total = 0;
		this.payment_total = 0;
		this.payment_ref_no = '';
		this.payment_qr_code = '';
		this.payment_qr_total = 0;
		this.payment_slip = '';
		this.css_img = '';
		this.css_room = '';
		this.css_room_size = '';
		this.mode = '';
		this.work_plan_field_id = 0;
	}
}
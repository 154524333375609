import { Injectable, NgModule } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpClientModule } from '@angular/common/http';
// RxJS
import { Observable, BehaviorSubject } from 'rxjs';
import { ConditionSearchModel, } from '../models/ConditionSearchModel';
import { MasterModel, } from '../models/MasterModel';
import { environment } from '../../environments/environment';
// Model

@Injectable()
export class WebServiceMaster {

  constructor(private http: HttpClient,) { }

  headers = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded'
  });

  getDataStatusActive(): Observable<MasterModel> {
    // Note: Add headers if needed (tokens/bearer)
    //const httpParams = this.httpUtils.getFindHTTPParams(id);

    const url = `${environment.API_SERVICES_URL}Base_Value/GetDataStatusActive`;
    return this.http.get<MasterModel>(url);

  }


  getDataServiceType(): Observable<MasterModel> {
    // Note: Add headers if needed (tokens/bearer)
    //const httpParams = this.httpUtils.getFindHTTPParams(id);

    const url = `${environment.API_SERVICES_URL}Base_Value/GetDataServiceType`;
    return this.http.get<MasterModel>(url);

  }


  getDataChangwat(): Observable<MasterModel> {
    // Note: Add headers if needed (tokens/bearer)
    //const httpParams = this.httpUtils.getFindHTTPParams(id);
    const condition = new ConditionSearchModel();

    const url = `${environment.API_SERVICES_URL}Base_Value/GetDataChanwat`;
    return this.http.post<MasterModel>(url, condition);

  }


  getDataAmphur(changwat_id: number): Observable<MasterModel> {
    // Note: Add headers if needed (tokens/bearer)
    //const httpParams = this.httpUtils.getFindHTTPParams(id);
    const condition = new ConditionSearchModel();
    condition.id = changwat_id;

    const url = `${environment.API_SERVICES_URL}Base_Value/GetDataAmphur`;
    return this.http.post<MasterModel>(url, condition);

  }


  getDataThumbol(amphur_id: number): Observable<MasterModel> {
    // Note: Add headers if needed (tokens/bearer)
    //const httpParams = this.httpUtils.getFindHTTPParams(id);

    const condition = new ConditionSearchModel();
    condition.id = amphur_id;

    const url = `${environment.API_SERVICES_URL}Base_Value/GetDataThumbol`;
    return this.http.post<MasterModel>(url, condition);

  }

  getDataZipcode(thumbol_id: number): Observable<string> {
    // Note: Add headers if needed (tokens/bearer)
    //const httpParams = this.httpUtils.getFindHTTPParams(id);

    const condition = new ConditionSearchModel();
    condition.id = thumbol_id;

    const url = `${environment.API_SERVICES_URL}Base_Value/GetDataZipcode`;
    return this.http.post<string>(url, condition);

  }

  getDataServerDate(day: number, date_start_string: string): Observable<MasterModel> {
    // Note: Add headers if needed (tokens/bearer)
    //const httpParams = this.httpUtils.getFindHTTPParams(id);
    const condition = new ConditionSearchModel();
    condition.day = day;
    condition.date_start = date_start_string

    const url = `${environment.API_SERVICES_URL}Base_Value/GetDataServerDate`;
    return this.http.post<MasterModel>(url, condition);

  }

  getDataIndex(tb_name: string, search: string): Observable<MasterModel> {
    // Note: Add headers if needed (tokens/bearer)
    //const httpParams = this.httpUtils.getFindHTTPParams(id);
    //const httpHeaders = new HttpHeaders();
    const userToken = localStorage.getItem("access_token");
    const httpHeaders = new HttpHeaders({
      Authorization: "Bearer " + userToken,
    });

    const condition = new ConditionSearchModel();
    condition.search = search;

    const url = `${environment.API_SERVICES_URL}` + tb_name + `/GetDataIndex`;
    return this.http.post<MasterModel>(url, condition, { headers: httpHeaders });

  }

  getDataById(tb_name: string, id: number): Observable<MasterModel> {
    // Note: Add headers if needed (tokens/bearer)
    //const httpParams = this.httpUtils.getFindHTTPParams(id);
    //const httpHeaders = new HttpHeaders();
    const userToken = localStorage.getItem("access_token");
    const httpHeaders = new HttpHeaders({
      Authorization: "Bearer " + userToken,
    });

    const condition = new ConditionSearchModel();
    condition.id = id;

    const url = `${environment.API_SERVICES_URL}` + tb_name + `/GetDataById`;
    return this.http.post<MasterModel>(url, condition, { headers: httpHeaders });

  }

  getDataAll(tb_name: string): Observable<MasterModel> {
    // Note: Add headers if needed (tokens/bearer)
    //const httpParams = this.httpUtils.getFindHTTPParams(id);
    //const httpHeaders = new HttpHeaders();
    const userToken = localStorage.getItem("access_token");
    const httpHeaders = new HttpHeaders({
      Authorization: "Bearer " + userToken,
    });

    const condition = new ConditionSearchModel();
    //condition.search = this.search;

    const url = `${environment.API_SERVICES_URL}` + tb_name + `/GetDataAll`;
    return this.http.post<MasterModel>(url, condition, { headers: httpHeaders });

  }

  saveData(tb_name: string, model: MasterModel): Observable<string> {
    // Note: Add headers if needed (tokens/bearer)
    //const httpParams = this.httpUtils.getFindHTTPParams(id);
    //const httpHeaders = new HttpHeaders();
    const userToken = localStorage.getItem("access_token");
    const httpHeaders = new HttpHeaders({
      Authorization: "Bearer " + userToken,
    });

    const url = `${environment.API_SERVICES_URL}` + tb_name + `/SaveData`;
    return this.http.post<string>(url, model, { headers: httpHeaders });
  }

  deleteData(tb_name: string, id: number): Observable<string> {
    // Note: Add headers if needed (tokens/bearer)
    //const httpParams = this.httpUtils.getFindHTTPParams(id);
    //const httpHeaders = new HttpHeaders();
    const userToken = localStorage.getItem("access_token");
    const httpHeaders = new HttpHeaders({
      Authorization: "Bearer " + userToken,
    });

    const condition = new ConditionSearchModel();
    condition.id = id;

    const url = `${environment.API_SERVICES_URL}` + tb_name + `/DeleteData`;
    return this.http.post<string>(url, condition, { headers: httpHeaders });
  }

}


import { Injectable, NgModule } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpClientModule } from '@angular/common/http';
// RxJS
import { Observable, BehaviorSubject } from 'rxjs';
import { ConditionSearchModel, } from '../models/ConditionSearchModel';
import { CustomerModel, } from '../models/CustomerModel';
import { environment } from '../../environments/environment';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import * as moment from 'moment';
// Model

@Injectable()
export class WebServiceCustomer {

    constructor(private http: HttpClient,) { }
    /* parameter page */
    page_id: number = 0;
    page_name: string = '';

    headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
    });

    getDataIndex(search: string): Observable<CustomerModel> {
        // Note: Add headers if needed (tokens/bearer)
        //const httpParams = this.httpUtils.getFindHTTPParams(id);
        //const httpHeaders = new HttpHeaders();
        const userToken = localStorage.getItem("access_token");
        const httpHeaders = new HttpHeaders({        
          Authorization: "Bearer " + userToken,
        });


        const condition = new ConditionSearchModel();
        condition.search = search;

        const url = `${environment.API_SERVICES_URL}Customer/GetDataIndex`;
        return this.http.post<CustomerModel>(url, condition, { headers: httpHeaders });

    }

    getDataById(id: number): Observable<CustomerModel> {
        // Note: Add headers if needed (tokens/bearer)
        //const httpParams = this.httpUtils.getFindHTTPParams(id);
        //const httpHeaders = new HttpHeaders();
        const userToken = localStorage.getItem("access_token");
        const httpHeaders = new HttpHeaders({        
          Authorization: "Bearer " + userToken,
        });


        const condition = new ConditionSearchModel();
        condition.id = id;

        const url = `${environment.API_SERVICES_URL}Customer/GetDataById`;
        return this.http.post<CustomerModel>(url, condition, { headers: httpHeaders });

    }

    getDataAll(): Observable<CustomerModel> {
        // Note: Add headers if needed (tokens/bearer)
        //const httpParams = this.httpUtils.getFindHTTPParams(id);
        //const httpHeaders = new HttpHeaders();
        const userToken = localStorage.getItem("access_token");
        const httpHeaders = new HttpHeaders({        
          Authorization: "Bearer " + userToken,
        });

        const condition = new ConditionSearchModel();
        //condition.search = this.search;

        const url = `${environment.API_SERVICES_URL}Customer/GetDataAll`;
        return this.http.post<CustomerModel>(url, condition, { headers: httpHeaders });

    }

    saveData(model: CustomerModel): Observable<string> {
        // Note: Add headers if needed (tokens/bearer)
        //const httpParams = this.httpUtils.getFindHTTPParams(id);
        //const httpHeaders = new HttpHeaders();
        const userToken = localStorage.getItem("access_token");
        const httpHeaders = new HttpHeaders({        
          Authorization: "Bearer " + userToken,
        });


        if (model.date_of_birth !== null) {
            model.date_of_birth_string = moment(model.date_of_birth).format('YYYY-MM-DD');
        }
        else
            model.date_of_birth_string = '';

        if (model.date_of_start !== null) {
            model.date_of_start_string = moment(model.date_of_start).format('YYYY-MM-DD');
        }
        else
            model.date_of_start_string = '';

        const url = `${environment.API_SERVICES_URL}Customer/SaveData`;
        return this.http.post<string>(url, model, { headers: httpHeaders });
    }

    deleteData(id: number): Observable<string> {
        // Note: Add headers if needed (tokens/bearer)
        //const httpParams = this.httpUtils.getFindHTTPParams(id);
        //const httpHeaders = new HttpHeaders();
        const userToken = localStorage.getItem("access_token");
        const httpHeaders = new HttpHeaders({        
          Authorization: "Bearer " + userToken,
        });

        const condition = new ConditionSearchModel();
        condition.id = id;

        const url = `${environment.API_SERVICES_URL}Customer/DeleteData`;
        return this.http.post<string>(url, condition, { headers: httpHeaders });
    }
}

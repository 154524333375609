export class UserModel {
	username: string;
	password: string;
	employee_id: number;
	employee_name: string;
	email: string;
	telephone: string;
	status_id: number;
	status_name: string;
	token: string;
	
	clear() {
		this.username = '';
		this.password = '';
		this.employee_id = 0;
		this.employee_name = '';
		this.email = '';
		this.telephone = '';
		this.status_id = 0;
		this.status_name = '';
		this.token = '';
	}
}
export class ScheduleMeetingTimeModel {
	rowNo: number;
    id: number;
	date: string;
	name: string;
	time_id: number;
	time_date: string;
	time_date_string: string;
	time_start: string;
	time_end: string;
	sort: number;
	time_rage_id: number;
	time_rage_name: string;
	status_id: number;
	status_name: string;
	css_room: string;
	price: number;
	status_select_id: number;

	clear() {
		this.rowNo = 0;
		this.id = 0;
		this.date = '';
		this.name = '';
		this.time_id = 0;
		this.time_date = '';
		this.time_date_string = '';
		this.time_start = '';
		this.time_end = '';
		this.sort = 0;
		this.time_rage_id = 0;
		this.time_rage_name = '';
		this.status_id = 0;
		this.status_name = '';
		this.css_room = '';
		this.status_select_id = 0;
	}
}
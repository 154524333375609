
import { Injectable, NgModule } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpClientModule } from '@angular/common/http';
// RxJS
import { Observable, BehaviorSubject } from 'rxjs';
import { ConditionSearchModel, } from '../models/ConditionSearchModel';
import { RoomModel, } from '../models/RoomModel';
import { environment } from '../../environments/environment';
// Model
import { MasterModel } from './../models/MasterModel';
import { TransferModel } from './../models/TransferModel';
import { ScheduleMeetingRoomModel } from './../models/ScheduleMeetingRoomModel';
import { ScheduleMeetingTimeModel } from './../models/ScheduleMeetingTimeModel';
import { ScheduleMeetingMeetingModel } from './../models/ScheduleMeetingMeetingModel';
import * as moment from 'moment';

@Injectable()
export class WebServiceServiceRoom {

    constructor(private http: HttpClient,) { }
        
    headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
    });

    GetDataServiceRoomScheduleRoom(date_start: Date, time_rage_id: number): Observable<ScheduleMeetingRoomModel> {
        // Note: Add headers if needed (tokens/bearer)
        //const httpParams = this.httpUtils.getFindHTTPParams(id);
        //const httpHeaders = new HttpHeaders();
        const userToken = localStorage.getItem("access_token");
        const httpHeaders = new HttpHeaders({        
          Authorization: "Bearer " + userToken,
        });
        
        var date_start_s: string = '';
        if (date_start !== null) {
            date_start_s = moment(date_start).format('YYYY-MM-DD');
        }

        const condition = new ConditionSearchModel();
        condition.date_start_s = date_start_s;
        condition.time_rage_id = time_rage_id;

        const url = `${environment.API_SERVICES_URL}Service_Room/GetDataServiceRoomScheduleRoom`;
        return this.http.post<ScheduleMeetingRoomModel>(url, condition, { headers: httpHeaders });

    }

    GetDataServiceRoomScheduleTime(date_start: Date, time_rage_id: number): Observable<ScheduleMeetingTimeModel> {
        // Note: Add headers if needed (tokens/bearer)
        //const httpParams = this.httpUtils.getFindHTTPParams(id);
        //const httpHeaders = new HttpHeaders();
        const userToken = localStorage.getItem("access_token");
        const httpHeaders = new HttpHeaders({        
          Authorization: "Bearer " + userToken,
        });
        
        var date_start_s: string = '';
        if (date_start !== null) {
            date_start_s = moment(date_start).format('YYYY-MM-DD');
        }
            
        const condition = new ConditionSearchModel();
        condition.date_start_s = date_start_s;
        condition.time_rage_id = time_rage_id;

        const url = `${environment.API_SERVICES_URL}Service_Room/GetDataServiceRoomScheduleTime`;
        return this.http.post<ScheduleMeetingTimeModel>(url, condition, { headers: httpHeaders });

    }

    
    GetDataServiceRoomScheduleMeeting(date_start: Date, time_rage_id: number): Observable<ScheduleMeetingMeetingModel> {
        // Note: Add headers if needed (tokens/bearer)
        //const httpParams = this.httpUtils.getFindHTTPParams(id);
        //const httpHeaders = new HttpHeaders();
        const userToken = localStorage.getItem("access_token");
        const httpHeaders = new HttpHeaders({        
          Authorization: "Bearer " + userToken,
        });

        var date_start_s: string = '';
        if (date_start !== null) {
            date_start_s = moment(date_start).format('YYYY-MM-DD');
        }
        const condition = new ConditionSearchModel();
        condition.date_start_s = date_start_s;
        condition.time_rage_id = time_rage_id;

        const url = `${environment.API_SERVICES_URL}Service_Room/GetDataServiceRoomScheduleMeeting`;
        return this.http.post<ScheduleMeetingMeetingModel>(url, condition, { headers: httpHeaders });

    }

    GetDataServiceRoomCheckTransferCount(): Observable<TransferModel> {
        // Note: Add headers if needed (tokens/bearer)
        //const httpParams = this.httpUtils.getFindHTTPParams(id);
        //const httpHeaders = new HttpHeaders();
        const userToken = localStorage.getItem("access_token");
        const httpHeaders = new HttpHeaders({        
          Authorization: "Bearer " + userToken,
        });

        const condition = new ConditionSearchModel();

        const url = `${environment.API_SERVICES_URL}Service_Room/GetDataServiceRoomCheckTransferCount`;
        return this.http.post<TransferModel>(url, condition, { headers: httpHeaders });

    }

    SaveDataServiceRoomBooking(model: ScheduleMeetingMeetingModel): Observable<string> {
        // Note: Add headers if needed (tokens/bearer)
        //const httpParams = this.httpUtils.getFindHTTPParams(id);
        //const httpHeaders = new HttpHeaders();
        const userToken = localStorage.getItem("access_token");
        const httpHeaders = new HttpHeaders({        
          Authorization: "Bearer " + userToken,
        });
        const url = `${environment.API_SERVICES_URL}Service_Room/SaveDataServiceRoomBooking`;
        return this.http.post<string>(url, model, { headers: httpHeaders });
    }

    SaveDataServiceRoomBookingToMeeting(model: ScheduleMeetingMeetingModel): Observable<string> {
        // Note: Add headers if needed (tokens/bearer)
        //const httpParams = this.httpUtils.getFindHTTPParams(id);
        //const httpHeaders = new HttpHeaders();
        const userToken = localStorage.getItem("access_token");
        const httpHeaders = new HttpHeaders({        
          Authorization: "Bearer " + userToken,
        });
        const url = `${environment.API_SERVICES_URL}Service_Room/SaveDataServiceRoomBookingToMeeting`;
        return this.http.post<string>(url, model, { headers: httpHeaders });
    }
    
    SaveDataServiceRoomMeetingToSuccess(model: ScheduleMeetingMeetingModel): Observable<string> {
        // Note: Add headers if needed (tokens/bearer)
        //const httpParams = this.httpUtils.getFindHTTPParams(id);
        //const httpHeaders = new HttpHeaders();
        const userToken = localStorage.getItem("access_token");
        const httpHeaders = new HttpHeaders({        
          Authorization: "Bearer " + userToken,
        });
        const url = `${environment.API_SERVICES_URL}Service_Room/SaveDataServiceRoomMeetingToSuccess`;
        return this.http.post<string>(url, model, { headers: httpHeaders });
    }
    
    GetDataServiceRoomCheckFullTime(): Observable<MasterModel> {
        // Note: Add headers if needed (tokens/bearer)
        //const httpParams = this.httpUtils.getFindHTTPParams(id);
        //const httpHeaders = new HttpHeaders();
        const userToken = localStorage.getItem("access_token");
        const httpHeaders = new HttpHeaders({        
          Authorization: "Bearer " + userToken,
        });
        const condition = new ConditionSearchModel();
        //condition.id = id;

        const url = `${environment.API_SERVICES_URL}Service_Room/GetDataServiceRoomCheckFullTime`;
        return this.http.post<MasterModel>(url, condition, { headers: httpHeaders });
    }


    cancelData(id: number, description: string): Observable<string> {
        // Note: Add headers if needed (tokens/bearer)
        //const httpParams = this.httpUtils.getFindHTTPParams(id);
        //const httpHeaders = new HttpHeaders();
        const userToken = localStorage.getItem("access_token");
        const httpHeaders = new HttpHeaders({        
          Authorization: "Bearer " + userToken,
        });
        const condition = new ConditionSearchModel();
        condition.id = id;
        condition.description = description;
        // console.log(description);
        const url = `${environment.API_SERVICES_URL}Service_Room/CancelDataServiceRoomBooking`;
        return this.http.post<string>(url, condition, { headers: httpHeaders });
    }

    GetDataServiceRoomCheckTransfer(): Observable<TransferModel> {
        // Note: Add headers if needed (tokens/bearer)
        //const httpParams = this.httpUtils.getFindHTTPParams(id);
        //const httpHeaders = new HttpHeaders();
        const userToken = localStorage.getItem("access_token");
        const httpHeaders = new HttpHeaders({        
          Authorization: "Bearer " + userToken,
        });
        const condition = new ConditionSearchModel();
        //condition.id = id;

        const url = `${environment.API_SERVICES_URL}Service_Room/GetDataServiceRoomCheckTransfer`;
        return this.http.post<TransferModel>(url, condition, { headers: httpHeaders });
    }

    SaveDataServiceRoomTransferConfirm(id: number): Observable<string> {
        // Note: Add headers if needed (tokens/bearer)
        //const httpParams = this.httpUtils.getFindHTTPParams(id);
        //const httpHeaders = new HttpHeaders();
        const userToken = localStorage.getItem("access_token");
        const httpHeaders = new HttpHeaders({        
          Authorization: "Bearer " + userToken,
        });
        const condition = new ConditionSearchModel();
        condition.id = id;
        const url = `${environment.API_SERVICES_URL}Service_Room/SaveDataServiceRoomTransferConfirm`;
        return this.http.post<string>(url, condition, { headers: httpHeaders });
    }
    
    SaveDataServiceRoomTransferNotConfirm(id: number): Observable<string> {
        // Note: Add headers if needed (tokens/bearer)
        //const httpParams = this.httpUtils.getFindHTTPParams(id);
        //const httpHeaders = new HttpHeaders();
        const userToken = localStorage.getItem("access_token");
        const httpHeaders = new HttpHeaders({        
          Authorization: "Bearer " + userToken,
        });
        const condition = new ConditionSearchModel();
        condition.id = id;
        const url = `${environment.API_SERVICES_URL}Service_Room/SaveDataServiceRoomTransferNotConfirm`;
        return this.http.post<string>(url, condition, { headers: httpHeaders });
    }

}

export class TransferModel {
	RowNo: number;
    id: number;
	code: string;
	doc_date: Date;
	doc_date_string: string;
	transfer_code: string;
	transfer_date: Date;
	transfer_date_string: string;
	service_type: number;
	service_id:	number;
	bank_id: number;
	bank_name: string;
	bank_account_name: string;
	bank_account_no: string;
	to_bank_id:	number;
	to_bank_name: string;
	to_bank_account_name: string;
	to_bank_account_no:	string;
	amount_total:	number;
	amount_receive:	number;
	create_date: Date;
	create_date_string: string;
	total: number;

	clear() {
		this.RowNo = 0;
		this.id = 0;
		this.code = '';
		this.doc_date = new Date;
		this.doc_date_string = '';
		this.transfer_code = '';
		this.transfer_date = new Date;
		this.transfer_date_string = '';
		this.service_type = 0;
		this.service_id = 0;
		this.bank_id = 0;
		this.bank_name = '';
		this.bank_account_name = '';
		this.bank_account_no = '';
		this.to_bank_id = 0;
		this.to_bank_name = '';
		this.to_bank_account_name = '';
		this.to_bank_account_no = '';
		this.amount_total = 0;
		this.amount_receive = 0;
		this.create_date = new Date;
		this.create_date_string = '';	
		this.total = 0;
	}
}

import { Injectable, NgModule } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpClientModule, HttpRequest } from '@angular/common/http';
// RxJS
import { Observable, BehaviorSubject, from, of, throwError } from 'rxjs';
import { map } from "rxjs/operators";
import { JwtHelperService } from "@auth0/angular-jwt";
import { ConditionSearchModel, } from '../models/ConditionSearchModel';
import { UserModel, } from '../models/UserModel';
import { TicketModel, } from '../models/TicketModel';
import { TransferModel, } from '../models/TransferModel';
import { CustomerModel, } from '../models/CustomerModel';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import * as moment from 'moment';
// Model
import { ScheduleMeetingRoomModel } from './../models/ScheduleMeetingRoomModel';
import { ScheduleMeetingTimeModel } from './../models/ScheduleMeetingTimeModel';
import { ScheduleMeetingMeetingModel } from './../models/ScheduleMeetingMeetingModel';
import { LineModel } from './../models/LineModel';

@Injectable()
export class WebServiceLine {

  userInfo = new BehaviorSubject(null);
  jwtHelper = new JwtHelperService();

  constructor(
    private router: Router,
    private http: HttpClient,
    //private readonly storage: Storage
  ) { }

  /* parameter page */
  page_id: number = 0;
  page_name: string = '';

  headers = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT',
    'Accept': 'application/json',
    //'content-type': 'application/json',
  });

  getDataLineLogin(username: string): Observable<boolean> {
    // Note: Add headers if needed (tokens/bearer)
    //const httpParams = this.httpUtils.getFindHTTPParams(id);

    const login = new UserModel();
    login.username = username;
    //login.password = password;

    // const url = `${environment.API_SERVICES_URL}Login/Login`;
    // return this.http.post<UserModel>(url, login);

    const url = `${environment.API_SERVICES_URL}LineLogin/LineLogin`;
    return this.http.post<UserModel>(url, login).pipe(
      map((response: any) => {
        if (response != null) {
          localStorage.setItem("access_token", response.value.token);
          var decodedUser = this.jwtHelper.decodeToken(response.value.token);
          this.userInfo.next(decodedUser);
          //console.log(decodedUser);
          return true;
        }
        else {
          return false;
        }
      })
    )

  }

  getDataLineLoginApp(): Observable<boolean> {
    // Note: Add headers if needed (tokens/bearer)
    //const httpParams = this.httpUtils.getFindHTTPParams(id);

    const login = new UserModel();
    // login.username = username;
    // login.password = password;

    // const url = `${environment.API_SERVICES_URL}Login/Login`;
    // return this.http.post<UserModel>(url, login);

    const url = `https://liff.line.me/1655893856-39VpgpGe`;
    return this.http.get<LineModel>(url, { headers: this.headers }).pipe(
      map((response: any) => {
        if (response != null) {
          // localStorage.setItem("access_token", response.value.token);
          // var decodedUser = this.jwtHelper.decodeToken(response.value.token);
          // this.userInfo.next(decodedUser);
          console.log(response);
          return true;
        }
        else {
          return false;
        }
      })
    )

  }

  logOut(): Observable<boolean> {
    localStorage.removeItem("access_token");
    return of(true);
  }

  getDataProfile(): Observable<CustomerModel> {
    const userToken = localStorage.getItem("access_token");
    const httpHeaders = new HttpHeaders({
      Authorization: "Bearer " + userToken,
    });

    const condition = new ConditionSearchModel();
    //condition.search = this.search;

    const url = `${environment.API_SERVICES_URL}Customer/GetDataProfile`;
    return this.http.post<CustomerModel>(url, condition, { headers: httpHeaders });
  }

  getDataTicketByUserId(): Observable<TicketModel> {
    const userToken = localStorage.getItem("access_token");
    const httpHeaders = new HttpHeaders({
      Authorization: "Bearer " + userToken,
    });

    const condition = new ConditionSearchModel();
    //condition.search = this.search;

    const url = `${environment.API_SERVICES_URL}LineTicket/GetDataTicketByUserId`;
    return this.http.post<TicketModel>(url, condition, { headers: httpHeaders });
  }

  saveDataTicketToTransfer(model: TransferModel): Observable<string> {
    const userToken = localStorage.getItem("access_token");
    const httpHeaders = new HttpHeaders({
      Authorization: "Bearer " + userToken,
    });

    if (model.transfer_date !== null) {
      model.transfer_date_string = moment(model.transfer_date).format('YYYY-MM-DD HH:mm');
    }
    else
      model.transfer_date_string = '';

    const url = `${environment.API_SERVICES_URL}LineTicket/SaveDataTicketToTransfer`;
    return this.http.post<string>(url, model, { headers: httpHeaders });
  }

  saveDataCheckIn(id: number, type: number): Observable<string> {
    const userToken = localStorage.getItem("access_token");
    const httpHeaders = new HttpHeaders({
      Authorization: "Bearer " + userToken,
    });

    const model = new TransferModel();
    model.service_id = id;
    model.service_type = type;

    const url = `${environment.API_SERVICES_URL}LineTicket/SaveDataCheckIn`;
    return this.http.post<string>(url, model, { headers: httpHeaders });
  }

  saveDataCheckInByCode(code: string, type: number): Observable<string> {
    const userToken = localStorage.getItem("access_token");
    const httpHeaders = new HttpHeaders({
      Authorization: "Bearer " + userToken,
    });

    const model = new TransferModel();
    model.code = code;
    model.service_type = type;

    const url = `${environment.API_SERVICES_URL}LineTicket/SaveDataCheckInByCode`;
    return this.http.post<string>(url, model, { headers: httpHeaders });
  }

  saveDataCustomerEdit(model: CustomerModel): Observable<string> {
    const userToken = localStorage.getItem("access_token");
    const httpHeaders = new HttpHeaders({
      Authorization: "Bearer " + userToken,
    });

    const url = `${environment.API_SERVICES_URL}LineTicket/SaveDataCustomerEdit`;
    return this.http.post<string>(url, model, { headers: httpHeaders });
  }

  
  saveDataCustomerRegister(model: CustomerModel): Observable<string> {
    const userToken = localStorage.getItem("access_token");
    const httpHeaders = new HttpHeaders({
      Authorization: "Bearer " + userToken,
    });

    const url = `${environment.API_SERVICES_URL}LineTicket/SaveDataCustomerRegister`;
    return this.http.post<string>(url, model, { headers: httpHeaders });
  }

  GetDataServiceLineScheduleTimeByLine(date_start: string, time_rage_id: number, type_id: number): Observable<ScheduleMeetingTimeModel> {
    const userToken = localStorage.getItem("access_token");
    const httpHeaders = new HttpHeaders({
      Authorization: "Bearer " + userToken,
    });

    var date_start_s: string = '';
    if (date_start !== null) {
      date_start_s = moment(date_start).format('YYYY-MM-DD');
    }

    const condition = new ConditionSearchModel();
    condition.date_start_s = date_start_s;
    condition.time_rage_id = time_rage_id;
    condition.type_id = type_id;

    const url = `${environment.API_SERVICES_URL}LineTicket/GetDataServiceRoomScheduleTime`;
    return this.http.post<ScheduleMeetingTimeModel>(url, condition, { headers: httpHeaders });

  }


  GetDataServiceLineScheduleRoomByLine(date_start: string, time_id: number): Observable<any> {
    const userToken = localStorage.getItem("access_token");
    const httpHeaders = new HttpHeaders({
      Authorization: "Bearer " + userToken,
    });

    var date_start_s: string = '';
    if (date_start !== null) {
      date_start_s = moment(date_start).format('YYYY-MM-DD');
    }

    const condition = new ConditionSearchModel();
    condition.date_start_s = date_start_s;
    condition.id = time_id;

    const url = `${environment.API_SERVICES_URL}LineTicket/GetDataServiceRoomScheduleRoom`;
    return this.http.post<any>(url, condition, { headers: httpHeaders });

  }


  GetDataServiceLineScheduleTableByLine(date_start: string, time_id: number): Observable<any> {
    const userToken = localStorage.getItem("access_token");
    const httpHeaders = new HttpHeaders({
      Authorization: "Bearer " + userToken,
    });

    var date_start_s: string = '';
    if (date_start !== null) {
      date_start_s = moment(date_start).format('YYYY-MM-DD');
    }

    const condition = new ConditionSearchModel();
    condition.date_start_s = date_start_s;
    condition.id = time_id;

    const url = `${environment.API_SERVICES_URL}LineTicket/GetDataServiceRoomScheduleTable`;
    return this.http.post<any>(url, condition, { headers: httpHeaders });

  }


  SaveDataServiceRoomBooking(model: ScheduleMeetingMeetingModel): Observable<string> {
    const userToken = localStorage.getItem("access_token");
    const httpHeaders = new HttpHeaders({
      Authorization: "Bearer " + userToken,
    });

    const url = `${environment.API_SERVICES_URL}LineTicket/SaveDataServiceRoomBooking`;
    return this.http.post<string>(url, model, { headers: httpHeaders });
  }


  SaveDataServiceTableBooking(model: ScheduleMeetingMeetingModel): Observable<string> {
    const userToken = localStorage.getItem("access_token");
    const httpHeaders = new HttpHeaders({
      Authorization: "Bearer " + userToken,
    });

    const url = `${environment.API_SERVICES_URL}LineTicket/SaveDataServiceTableBooking`;
    return this.http.post<string>(url, model, { headers: httpHeaders });
  }

  SaveDataServiceFieldBookingBiller(date_start: string, total_price: number): Observable<number> {
    const userToken = localStorage.getItem("access_token");
    const httpHeaders = new HttpHeaders({
      Authorization: "Bearer " + userToken,
    });

    const model = new ScheduleMeetingTimeModel();
    model.price = total_price;

    if (date_start !== null) {
      model.time_date = moment(date_start).format('YYYY-MM-DD');
    }

    const url = `${environment.API_SERVICES_URL}LineTicket/SaveDataServiceFieldBookingBiller`;
    return this.http.post<number>(url, model, { headers: httpHeaders });
  }


  
  SaveDataServiceFieldBooking(model: ScheduleMeetingTimeModel, date_start: string): Observable<string> {
    const userToken = localStorage.getItem("access_token");
    const httpHeaders = new HttpHeaders({
      Authorization: "Bearer " + userToken,
    });

    if (date_start !== null) {
      model.time_date = moment(date_start).format('YYYY-MM-DD');
    }

    const url = `${environment.API_SERVICES_URL}LineTicket/SaveDataServiceFieldBooking`;
    return this.http.post<string>(url, model, { headers: httpHeaders });
  }

  

  GetDataServiceLinePaymentOfPaiding(): Observable<any> {
    const userToken = localStorage.getItem("access_token");
    const httpHeaders = new HttpHeaders({
      Authorization: "Bearer " + userToken,
    });

    const condition = new ConditionSearchModel();

    const url = `${environment.API_SERVICES_URL}LineTicket/GetDataServiceLinePaymentOfPaiding`;
    return this.http.post<any>(url, condition, { headers: httpHeaders });

  }

  saveDataTicketPaymentConfirm(id: number): Observable<string> {
    const userToken = localStorage.getItem("access_token");
    const httpHeaders = new HttpHeaders({
      Authorization: "Bearer " + userToken,
    });

    const condition = new ConditionSearchModel();
    condition.id = id;

    const url = `${environment.API_SERVICES_URL}LineTicket/SaveDataServicePaymentConfirm`;
    return this.http.post<string>(url, condition, { headers: httpHeaders });
  }

  saveDataRemoveOrder(id: number): Observable<string> {
    const userToken = localStorage.getItem("access_token");
    const httpHeaders = new HttpHeaders({
      Authorization: "Bearer " + userToken,
    });

    const condition = new ConditionSearchModel();
    condition.id = id;

    const url = `${environment.API_SERVICES_URL}LineTicket/SaveDataServicePaymentRemoveOrder`;
    return this.http.post<string>(url, condition, { headers: httpHeaders });
  }

  UploadFileImage(files: FormData): Observable<string> {
   
    const userToken = localStorage.getItem("access_token");
    const httpHeaders = new HttpHeaders({
      Authorization: "Bearer " + userToken,
    });
    const url = `${environment.API_SERVICES_URL}LineTicket/UploadFileImage`;
    return this.http.post<string>(url, files, { headers: httpHeaders });
  }

  saveDataTicketPaymentConfirm2(id: number, code:string, name:string, bank_id: number, 
    doc_ref: string, total_price: number, image_file_string: string, payment_date: string, payment_time: string): Observable<string> {
    const userToken = localStorage.getItem("access_token");
    const httpHeaders = new HttpHeaders({
      Authorization: "Bearer " + userToken,
    });


    const condition = new ConditionSearchModel();
    condition.id = id;
    condition.bank_id = bank_id;
    condition.code = code;
    condition.name = name;
    condition.doc_ref = doc_ref;
    condition.total_price = total_price;
    condition.image_file_string = image_file_string;
    
    if (payment_date !== null) {
      condition.payment_date = moment(payment_date).format('YYYY-MM-DD HH:mm');
    }

    const url = `${environment.API_SERVICES_URL}LineTicket/SaveDataServicePaymentConfirm2`;
    return this.http.post<string>(url, condition, { headers: httpHeaders });
  }

  saveDataTicketPaymentConfirm3(id: number, total_price: number): Observable<string> {
    const userToken = localStorage.getItem("access_token");
    const httpHeaders = new HttpHeaders({
      Authorization: "Bearer " + userToken,
    });


    const condition = new ConditionSearchModel();
    condition.id = id;
    condition.total_price = total_price;
    
    const url = `${environment.API_SERVICES_URL}LineTicket/SaveDataServicePaymentConfirm3`;
    return this.http.post<string>(url, condition, { headers: httpHeaders });
  }

  getDataTicketByUserIdState(bill_type_id: number): Observable<any> {
    const userToken = localStorage.getItem("access_token");
    const httpHeaders = new HttpHeaders({
      Authorization: "Bearer " + userToken,
    });

    const condition = new ConditionSearchModel();
    condition.id = bill_type_id;

    const url = `${environment.API_SERVICES_URL}LineTicket/GetDataTicketByUserIdState`;
    return this.http.post<any>(url, condition, { headers: httpHeaders });
  }

  
  getDataTicketByUserIdStateBook(bill_type_id: number): Observable<any> {
    const userToken = localStorage.getItem("access_token");
    const httpHeaders = new HttpHeaders({
      Authorization: "Bearer " + userToken,
    });

    const condition = new ConditionSearchModel();
    condition.id = bill_type_id;

    const url = `${environment.API_SERVICES_URL}LineTicket/GetDataTicketByUserIdStateBook`;
    return this.http.post<any>(url, condition, { headers: httpHeaders });
  }

  getDataCompanyAccount(): Observable<any> {
    const userToken = localStorage.getItem("access_token");
    const httpHeaders = new HttpHeaders({
      Authorization: "Bearer " + userToken,
    });

    const condition = new ConditionSearchModel();
    const url = `${environment.API_SERVICES_URL}LineTicket/GetDataCompanyAccount`;
    return this.http.post<any>(url, condition, { headers: httpHeaders });
  }

  getDataMemberGroupPay(): Observable<any> {
    const userToken = localStorage.getItem("access_token");
    const httpHeaders = new HttpHeaders({
      Authorization: "Bearer " + userToken,
    });

    const condition = new ConditionSearchModel();
    const url = `${environment.API_SERVICES_URL}LineTicket/GetDataMemberGroupPay`;
    return this.http.post<any>(url, condition, { headers: httpHeaders });
  }

  getDataBankAll(): Observable<any> {
    const userToken = localStorage.getItem("access_token");
    const httpHeaders = new HttpHeaders({
      Authorization: "Bearer " + userToken,
    });

    const condition = new ConditionSearchModel();
    const url = `${environment.API_SERVICES_URL}LineTicket/GetDataBankAll`;
    return this.http.post<any>(url, condition, { headers: httpHeaders });
  }
  
}

import { ScheduleMeetingMeetingModel } from './../models/ScheduleMeetingMeetingModel';
import { Component, OnInit, Input } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
//model
import { MasterModel } from '../models/MasterModel';
import { CustomerModel } from './../models/CustomerModel';
//webservice
import { WebServiceMaster } from './../services/web.service-master';
import { WebServiceCustomer } from './../services/web.service-customer';
import { WebServiceServiceRoom } from './../services/web.service-service-room';
import { formatCurrency } from '@angular/common';

@Component({
  selector: 'app-modal-new-service-room-meeting',
  templateUrl: './modal-new-service-room-meeting.component.html',
  styleUrls: ['./modal-new-service-room-meeting.component.scss'],
})
export class ModalNewServiceRoomMeetingComponent implements OnInit {

  // Data passed in by componentProps
  @Input() title_header: string;
  @Input() id: number;
  @Input() code: string;
  @Input() room_id: number;
  @Input() room_name: string;
  @Input() time_date_string: string;
  @Input() time_id: number;
  @Input() time_name: string;
  @Input() price: number;
  @Input() credit_minute: number;
  @Input() vat_type_id: number;
  @Input() vat_type_name: string;
  @Input() vat_per: number;
  @Input() customer_name: string;
  @Input() customer_telephone: string;
  @Input() net_total: number;
  @Input() payment_total: number;
  @Input() payment_ref_no: string;
  @Input() payment_status_name: string;
  description: string;

  constructor(public modalCtrl: ModalController,
    private webServiceServiceRoom: WebServiceServiceRoom,
    public alertController: AlertController,
  ) { }

  async close() {
    await this.modalCtrl.dismiss({ response: 1 });
  }

  dismiss() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

  ngOnInit() {
  }

  saveData() {

    var model = new ScheduleMeetingMeetingModel();
    model.id = this.id;
    this.webServiceServiceRoom.SaveDataServiceRoomBookingToMeeting(model).subscribe(result => {
      if (result == 'success') {
        this.dismiss();
      }
      else if (result == 'error') {
        alert('บันทึกข้อมูลไม่สำเร็จ!');
      }
    });
  }

  async cancelData(id: number, code: string) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'ยืนยันการลบข้อมูล!',
      message: code,
      inputs: [
        {
          name: 'description',
          placeholder: 'ระบุสาเหตุ!'
        }
      ],
      buttons: [
        {
          text: 'ยกเลิก',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }, {
          text: 'ยันยัน',
          handler: (data) => {
            this.description = data.description;     
            if (this.description == null || this.description == '') {
              this.description = 'ไม่ระบุสาเหตุ!';         
            }

            this.webServiceServiceRoom.cancelData(id, this.description).subscribe(result => {
              if (result == 'success') {
                this.dismiss();
              }
              else if (result == 'error') {
                console.log('ยกเลิกข้อมูลไม่สำเร็จ!');
              }
            });
          }
        }
      ]
    });
    await alert.present();
  }

}


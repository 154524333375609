import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [

  // {
  //   path: '',
  //   loadChildren: () => import('./menu/menu.module').then( m => m.MenuPageModule)
  // },
  {
    path : '',
    redirectTo : 'line-login',
    pathMatch : 'full'
  },
  {
    path: 'app',
    loadChildren: () => import('./tabs/tabs.module').then( m => m.TabsPageModule)
  },  
  {
    path: 'line-login',
    loadChildren: () => import('./line-login/line-login.module').then( m => m.LineLoginPageModule)
  },  
  {
    path: 'line-service-room-book',
    loadChildren: () => import('./line-service-room-book/line-service-room-book.module').then( m => m.LineServiceRoomBookPageModule)
  },  
  {
    path: 'line-payment-cart',
    loadChildren: () => import('./line-payment-cart/line-payment-cart.module').then( m => m.LinePaymentCartPageModule)
  },
  {
    path: 'line-payment-state',
    loadChildren: () => import('./line-payment-state/line-payment-state.module').then( m => m.LinePaymentStatePageModule)
  },
  {
    path: 'line-book-state',
    loadChildren: () => import('./line-book-state/line-book-state.module').then( m => m.LineBookStatePageModule)
  },
];


@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

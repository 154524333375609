import { NgModule, CUSTOM_ELEMENTS_SCHEMA, Pipe } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { NgxCaptchaModule } from 'ngx-captcha';


import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonIntlTelInputModule } from 'ion-intl-tel-input';

// ng model

// modal;
import { ModalNewServiceRoomMeetingComponent } from './modal-new-service-room-meeting/modal-new-service-room-meeting.component';
import { ModalNewServiceRoomSuccessComponent } from './modal-new-service-room-success/modal-new-service-room-success.component';
import { ModalLineRegisterComponent } from './modal-line-register/modal-line-register.component';

// web services
import { WebServiceMaster } from './services/web.service-master';
import { WebServiceLine } from './services/web.service-line';
import { WebServicePayment } from './services/web.service-payment';
import { WebServiceCustomer } from './services/web.service-customer';
import { WebServiceServiceRoom } from './services/web.service-service-room';

import {NgxNotificationMsgService} from 'ngx-notification-msg';

@NgModule({
  declarations: [
    AppComponent,
    ModalNewServiceRoomMeetingComponent,
    ModalNewServiceRoomSuccessComponent,
    ModalLineRegisterComponent
  ],
  entryComponents: [
    ModalNewServiceRoomMeetingComponent,
    ModalNewServiceRoomSuccessComponent,
    ModalLineRegisterComponent
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgCircleProgressModule.forRoot(),
    NgxQRCodeModule,
    NgxCaptchaModule,
		ReactiveFormsModule,
    IonIntlTelInputModule,
		NgxIntlTelInputModule,
    BrowserAnimationsModule
  ],
  exports:[],
  providers: [
  StatusBar,
  SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Pipe,
    HttpClient,
    WebServiceMaster,
    NgxNotificationMsgService,
    WebServiceLine,
    WebServicePayment,
    WebServiceCustomer,
    WebServiceServiceRoom
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
